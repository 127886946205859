import Vue from "vue";
import Component from "vue-class-component";
import { DgColumn, DgGridContainer, DgIconCard, DgModal, DgSeparator, DgText } from "@dasgate/uikit";
import { AuthPermissions, authPermissions, Roles } from "@/core/user/domain/auth";
import { BmsService } from "@/legacy/models/settings";
import { RouteNames } from "@/ui/_router";

@Component({
  components: {
    DgIconCard,
    DgColumn,
    DgGridContainer,
    DgSeparator,
    DgText,
    DgModal,
  },
})
export default class UserHome extends Vue {
  public get permissions(): AuthPermissions {
    return authPermissions(this.$container.authRepository.get());
  }

  public get availableServicesAuth(): BmsService[] {
    if (this.$appStore.getters.settings.bmsServices !== undefined) {
      const availableServices: BmsService[] = [];
      this.$appStore.getters.settings.bmsServices.forEach((element: BmsService) => {
        if (this.permissions.hasRole(element.required_role as Roles)) {
          availableServices.push(element);
        }
      });
      return availableServices;
    }
    return [];
  }

  public get knowMoreUrl(): string {
    return this.$appStore.getters.settings.knowMoreUrl
      ? this.$appStore.getters.settings.knowMoreUrl
      : "https://das-gate.com/";
  }

  public goToDeleteAccountAuth(): void {
    const userId = this.$container.authRepository.get()?.userId ?? "";
    this.$router.push({ name: RouteNames.DeleteAccount, params: { userId } });
  }

  public goToProfile(): void {
    this.$router.push({ name: RouteNames.UserProfile });
  }

  public goToResetCredentials(): void {
    this.$router.push({ name: RouteNames.ResetCredentials });
  }

  public goToNewPage(url: string): void {
    window.open(url, "_blank", "noreferrer");
  }
}
