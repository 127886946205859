import Vue from "vue";
import Component from "vue-class-component";
import { Emit, Prop } from "vue-property-decorator";
import Printd from "printd";
import { DgButton, DgColumn, DgFlex, DgText } from "@dasgate/uikit";

@Component({ components: { DgText, DgButton, DgColumn, DgFlex } })
export default class OnboardingSuccessQr extends Vue {
  @Prop({ required: false }) public qrUrl!: string;

  private print: Printd = new Printd();

  public get isAuto(): boolean {
    return this.$appStore.getters.registerMode === "auto";
  }

  public printQr(): void {
    this.print.print(this.$refs.print as HTMLElement);
  }

  @Emit() onFinish() {
    return;
  }
}
