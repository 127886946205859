export interface Settings {
  title: string;
  userRegisterEnabled: boolean;
  assistantRegisterEnabled: boolean;
  domain: string;
  biometricQR: boolean;
  acsIdSchema: "string64" | "wiegand26";
  registerTypes: { [registerType: string]: DynamicRegisterType[] };
  bmsServices: BmsService[];
  visits: Visits;
  auditAccessesLevel: string;
  locales: string[];
  sapConfigAuto?: SapConfig;
  sapConfigAssisted?: SapConfig;
  exclusiveDoc?: ExclusiveDocTypes;
  askFieldForDelete?: DynamicRegisterType;
  customImages?: boolean;
  knowMoreUrl?: string;
  watchlistDomains: string[];
  postEnrolmentAction?: string;
  useWatch?: boolean;
  onboardingSteps?: OnboardingSteps[];
  showNextSteps?: boolean;
  extendedInterdictionForm?: boolean;
}

export enum OnboardingSteps {
  ScanQr = "scan-qr", //iam
  IdProofing = "id-proofing",
  Selfie = "selfie",
  Form = "form",
  RequestVisit = "request-visit",
}
export enum ExclusiveDocTypes {
  idCard = "idCard",
}

export interface SapConfig {
  length: number;
  expiration: number;
}

export interface BrowserSetting {
  name: string;
  minDesktopVersion: number;
  minMobileVersion: number;
  downloadUrl: string;
}

export interface BmsService {
  name: string;
  url: string;
  required_role: string;
  title: string;
  label: string;
  icon: string;
}

export interface Visits {
  locations: Location[];
  mustAcceptPlan: boolean;
}

export interface Location {
  location: string;
  text: string;
  required_role: string;
  label: string;
}

export interface VisitsMustAccept {
  type: string;
  required: boolean;
}

export interface DynamicRegisterType {
  name: string;
  label: string;
  regex: string;
  type: string;
  min: number;
  max: number;
  required: boolean;
  validate_function: string;
  accept: string;
  editable: string[];
  placeholder: string;
  resize: boolean;
  lazy: boolean;
  minDate: string;
  maxDate: string;
  selectOptions: any[];
  uppercase: boolean;
  extra?: boolean;
  timezone: string;
}

export interface BrowserSettings {
  [name: string]: BrowserSetting;
}

export interface TenantSettings extends Settings {
  tenant: string;
  assetsUrl: string;
}
