import { OnboardingSteps } from "@/legacy/models/settings";
import { isUndefined } from "@/core/shared/utils";

export const getStepper = (onboardingSteps: OnboardingSteps[] | undefined, currentStep: OnboardingSteps) => {
  if (!onboardingSteps || isUndefined(onboardingSteps.find(step => step === currentStep))) {
    return undefined;
  }

  return {
    active: onboardingSteps.findIndex(step => step === currentStep) + 1,
    length: onboardingSteps.length,
  };
};

export const hasStepper = (onboardingSteps: OnboardingSteps[] | undefined, findStep: OnboardingSteps) => {
  if (!onboardingSteps) {
    return false;
  }

  return onboardingSteps.findIndex(step => step === findStep) >= 0;
};
