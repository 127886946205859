import { ConfirmOnboardingResponseErrorCode } from "@/legacy/features/onboarding/models/onboarding.models";
import { DelegatedOnboardingResponseErrorCode } from "@/legacy/features/portal/models/delegated-onboarding.models";

export const getErrorMessage = (errorCode: ConfirmOnboardingResponseErrorCode | DelegatedOnboardingResponseErrorCode) =>
  errorMessageMap[errorCode] ?? "common.onboarding.register-finish.finish-ko";

const errorMessageMap: Record<ConfirmOnboardingResponseErrorCode | DelegatedOnboardingResponseErrorCode, string> = {
  [ConfirmOnboardingResponseErrorCode.Unknown]: "common.onboarding.register-finish.finish-ko",
  [ConfirmOnboardingResponseErrorCode.UnderAge]: "common.onboarding.register-finish.finish-ko-underage",
  [ConfirmOnboardingResponseErrorCode.DocumentExpired]: "common.onboarding.register-finish.finish-ko-expired",
  [ConfirmOnboardingResponseErrorCode.ForbiddenList]: "common.onboarding.register-finish.finish-ko-forbidden-list",
  [ConfirmOnboardingResponseErrorCode.LifeProofingFailed]: "common.onboarding.register-finish.finish-ko-life-proofing",
  [ConfirmOnboardingResponseErrorCode.AlreadyRegistered]:
    "common.onboarding.register-finish.finish-ko-already-registered",
  [ConfirmOnboardingResponseErrorCode.DocumentAlreadyInUse]:
    "common.onboarding.register-finish.finish-ko-document-in-use",
  [ConfirmOnboardingResponseErrorCode.EnforcementSourceUnavailable]:
    "common.onboarding.register-finish.finish-ko-enforcement-source-unavailable",
  [DelegatedOnboardingResponseErrorCode.Unknown]: "common.onboarding.register-finish.finish-ko",
  [DelegatedOnboardingResponseErrorCode.DocumentExpired]: "common.onboarding.register-finish.finish-ko-expired",
  [DelegatedOnboardingResponseErrorCode.EmbeddingFailed]: "common.portal.batch-register.errors.3",
  [DelegatedOnboardingResponseErrorCode.IdAlreadyUsed]: "common.portal.register.errors.2",
  [DelegatedOnboardingResponseErrorCode.QrGenerationFailed]: "common.portal.register.errors.3",
};

export const getImageError = (errorCode: ConfirmOnboardingResponseErrorCode | DelegatedOnboardingResponseErrorCode) => {
  if (errorCode === ConfirmOnboardingResponseErrorCode.DocumentExpired) {
    return "img_document_ko";
  }

  if (errorCode === ConfirmOnboardingResponseErrorCode.ForbiddenList) {
    return "img_not_allowed_list";
  }

  return "img_onboarding_failed";
};
