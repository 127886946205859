import { DgButton, DgColumn, DgImage, DgText } from "@dasgate/uikit";
import Vue from "vue";
import Component from "vue-class-component";
import { Emit, Prop } from "vue-property-decorator";
import { getErrorMessage, getImageError } from "../../OnboardingResult.model";
import { ConfirmOnboardingResponseErrorCode } from "@/legacy/features/onboarding/models/onboarding.models";
import { DelegatedOnboardingResponseErrorCode } from "@/legacy/features/portal/models/delegated-onboarding.models";

@Component({ components: { DgImage, DgButton, DgColumn, DgText } })
export default class OnboardingError extends Vue {
  @Prop({ required: true }) public errorCode!:
    | ConfirmOnboardingResponseErrorCode
    | DelegatedOnboardingResponseErrorCode;

  public get title(): string {
    return getErrorMessage(this.errorCode);
  }

  public get image(): string {
    return getImageError(this.errorCode);
  }

  public get retryLabel() {
    return this.$appStore.getters.registerMode === "auto" ||
      this.errorCode == ConfirmOnboardingResponseErrorCode.EnforcementSourceUnavailable
      ? "common.retry"
      : "common.back";
  }

  @Emit() onClick() {
    return;
  }
}
