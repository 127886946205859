import Vue from "vue";
import Component from "vue-class-component";
import { DgBox, DgButton, DgColumn, DgImage, DgSeparator, DgStepper, DgText } from "@dasgate/uikit";
import { getStepper } from "@/ui/Onboarding/Tutorial/Tutorial.model";
import { OnboardingSteps } from "@/legacy/models/settings";

@Component({
  components: {
    DgText,
    DgImage,
    DgButton,
    DgStepper,
    DgColumn,
    DgSeparator,
    DgBox,
  },
})
export default class RequestVisitStep extends Vue {
  get stepper() {
    return getStepper(this.$appStore.getters.settings.onboardingSteps, OnboardingSteps.RequestVisit);
  }

  public get image(): string {
    return `img_onboarding_success`;
  }

  public onFinish() {
    this.$emit("on-finish");
  }
}
