import { DgBox, DgButton, DgColumn, DgImage, DgText } from "@dasgate/uikit";
import Vue from "vue";
import Component from "vue-class-component";
import { Emit } from "vue-property-decorator";
import { authPermissions } from "@/core/user/domain/auth";

@Component({ components: { DgText, DgImage, DgButton, DgColumn, DgBox } })
export default class OnboardingSuccess extends Vue {
  public get image(): string {
    return "img_onboarding_success";
  }

  public get description(): string {
    return this.$appStore.getters.lifeProofing && this.$appStore.getters.registerMode === "auto"
      ? "common.onboarding.register-finish.finish-ok-selfie-user"
      : "common.onboarding.register-finish.finish-ok-user";
  }

  public get finishLabel() {
    return authPermissions(this.$container.authRepository.get()).isUser()
      ? this.$t("common.finish")
      : this.$t("common.back");
  }

  public get showExtra(): boolean {
    return this.$te("common.onboarding.register-finish.extra-message-intro");
  }

  @Emit() onFinish() {
    return;
  }
}
