import Vue from "vue";
import Component from "vue-class-component";
import { RequestVisitStep } from "../Tutorial/RequestVisitStep";
import { OnboardingError } from "./_components/OnboardingError";
import { Loading } from "@/ui/_components/Loading";
import { OnboardingSuccessQr } from "./_components/OnboardingSuccessQr";
import { OnboardingSuccess } from "./_components/OnboardingSuccess";
import { Prop } from "vue-property-decorator";
import { ConfirmOnboardingResponseErrorCode } from "@/legacy/features/onboarding/models/onboarding.models";
import { DelegatedOnboardingResponseErrorCode } from "@/legacy/features/portal/models/delegated-onboarding.models";
import { authPermissions } from "@/core/user/domain/auth";
import { RouteNames } from "@/ui/_router";
import { redirectUser } from "@/legacy/features/common/shared";

@Component({ components: { OnboardingSuccessQr, Loading, OnboardingError, RequestVisitStep, OnboardingSuccess } })
export default class OnboardingResult extends Vue {
  get showVisitTutorial() {
    return this.success && (this.isVisitor || this.isReceptionist);
  }
  @Prop({ required: false, default: false }) public success!: boolean;
  @Prop({ required: false, default: "" }) public qrId!: string;
  @Prop({ required: false, default: "" }) public userId!: string;
  @Prop({ required: false, default: ConfirmOnboardingResponseErrorCode.Unknown })
  public errorCode!: ConfirmOnboardingResponseErrorCode | DelegatedOnboardingResponseErrorCode;

  public qrdata = "";
  public retrying = false;

  public async created() {
    if (this.showQr) {
      await this.getQrImage();
    }
  }

  public async mounted() {
    if (this.success && this.isAuto) {
      this.$container.authRepository.update({ enrolled: true });
    }
  }

  public get isAuto(): boolean {
    return authPermissions(this.$container.authRepository.get()).isUser();
  }

  public get isVisitor(): boolean {
    return this.$appStore.getters.isVisitor;
  }

  public get isReceptionist(): boolean {
    return this.$appStore.getters.isReceptionist;
  }

  public get showQr(): boolean {
    return !!this.qrId && this.success && !this.isVisitor;
  }

  public async finish(): Promise<void> {
    if (this.knowMoreUrl && this.success && this.isAuto) {
      window.open(this.knowMoreUrl);
    } else {
      if (this.isVisitor) {
        this.$router.push({ name: RouteNames.ProcessVisit });
      } else if (this.isReceptionist) {
        this.$router.push({ name: RouteNames.ProcessVisit, params: { userId: this.userId } });
      } else {
        await redirectUser();
      }
    }
  }

  public async retry(): Promise<void> {
    const onboardingId = this.$appStore.getters.onboardingId;
    const identityPortrayalId = this.$appStore.getters.identityPortrayalId;
    const confirmData = this.$appStore.getters.getConfirmData;
    if (Object.keys(confirmData).length > 0) {
      this.retrying = true;
      const result = await this.$services.onboardingService.confirm({
        onboardingId,
        identityPortrayalId,
        data: confirmData,
      });
      this.retrying = false;

      if (result.success) {
        this.$router.push({ name: RouteNames.FinishRegister });
        this.$appStore.actions.resetOnboarding();
      } else {
        if (!result.retry) {
          this.$appStore.actions.resetOnboarding();
        }
        this.$router.push({
          name: RouteNames.FinishRegisterWithError,
          params: { errorCode: result.code },
        });
        return;
      }
    } else {
      this.$appStore.actions.resetOnboarding();
      await redirectUser();
    }
  }

  private async getQrImage() {
    const response = await this.$services.userService.getQrImage({
      userId: this.userId,
      sid: this.qrId,
    });

    this.qrdata = URL.createObjectURL(response.content as unknown as Blob);
  }

  private get knowMoreUrl() {
    return this.$appStore.getters.settings.knowMoreUrl;
  }
}
